<template>
        <el-dialog title="Clubs" :visible.sync="showPopup" class="my-dialog-class" >
        <el-form label-position="top" :inline="false" :rules="rules" label-width="100%"  :model='selectedObject'  ref='my-form' @submit.prevent="save" v-loading="loadingVisible" >
            <el-row :gutter="15" >
                <el-col :md='24'>
                    <el-form-item label='Name' >
                        <el-input  class='full-width' v-model='selectedObject.Name' />
                    </el-form-item>
                </el-col>
                <el-col :md='24'>
                    <el-form-item label='Address' >
                        <el-input type="textarea" class='full-width' v-model='selectedObject.Address' />
                    </el-form-item>
                </el-col>
                <el-col :md='24'>
                    <el-form-item label='About' >
                        <el-input type="textarea" class='full-width' v-model='selectedObject.About' />
                    </el-form-item>
                </el-col>
                <el-col :md='12'>
                    <el-form-item label='Events Url' >
                        <el-input  class='full-width' v-model='selectedObject.EventsUrl' />
                    </el-form-item>
                </el-col>
                <el-col :md='12'>
                    <el-form-item label='Tickets Url' >
                        <el-input  class='full-width' v-model='selectedObject.TicketsUrl' />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :md='8'>
                    <el-form-item label='Has Club Host' >
                        <el-checkbox v-model='selectedObject.HasClubHost' true-label='1' false-label='0'> Has Club Host </el-checkbox>
                    </el-form-item>
                </el-col>
                <el-col :md='8'>
                    <el-form-item label='Has Tickets' >
                        <el-checkbox v-model='selectedObject.HasTickets' true-label='1' false-label='0'> Has Tickets </el-checkbox>
                    </el-form-item>
                </el-col>
                <el-col :md='8'>
                    <el-form-item label='Has Tables' >
                        <el-checkbox v-model='selectedObject.HasTables' true-label='1' false-label='0'> Has Tables </el-checkbox>
                    </el-form-item>
                </el-col>
                <el-col :md='8'>
                    <el-form-item label='Has Guest List' >
                        <el-checkbox v-model='selectedObject.HasGuestList' true-label='1' false-label='0'> Has Guest List </el-checkbox>
                    </el-form-item>
                </el-col>
                <el-col :md='8'>
                    <el-form-item label='Has Limo Pickup' >
                        <el-checkbox v-model='selectedObject.HasLimoPickup' true-label='1' false-label='0'> Has Limo Pickup </el-checkbox>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :md='8'>
                    <el-form-item label='Paused Guest List' >
                        <el-checkbox v-model='selectedObject.PausedGuestList' true-label='1' false-label='0'> Paused Guest List </el-checkbox>
                    </el-form-item>
                </el-col>
                <el-col :md='8'>
                    <el-form-item label='Paused Table Reservation' >
                        <el-checkbox v-model='selectedObject.PausedTableReservation' true-label='1' false-label='0'> Paused Table Reservation </el-checkbox>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
            <el-col :md='24'>
                <el-form-item label='TableLayoutUrl' >
                    <el-input  class='full-width' v-model='selectedObject.TableLayoutUrl' />
                </el-form-item>
            </el-col>
            <el-col :md='24'>
                <el-form-item label='TableInfo' >
                    <el-input  class='full-width' v-model='selectedObject.TableInfo' />
                </el-form-item>
            </el-col>
            <el-col :md='8'>
                <el-form-item label='ClubType' >
                     <el-select class='full-width' v-model='selectedObject.ClubType' >
                         <el-option label='nightclub' value='nightclub'></el-option>
                         <el-option label='dayclub' value='dayclub'></el-option>
                         <el-option label='ultralounge' value='ultralounge'></el-option>
                         <el-option label='gentleman' value='gentleman'></el-option>
                         <el-option label='ladies' value='ladies'></el-option>
                     </el-select>
                </el-form-item>
            </el-col>
            <el-col :md='24'>
                <el-form-item label='ImageFile' >
                <el-upload
                        class='my-uploader'
                        :action="baseUrl + 'Clubs/upload_ImageFile'"
                        :show-file-list='false'
                        :on-success='handleUploadSuccess_ImageFile'>
                            <i class='el-icon-plus my-uploader-icon'></i>
                        </el-upload>       
                        <img v-if='selectedObject.uploadPreview_ImageFile' :src='selectedObject.uploadPreview_ImageFile' > 
                </el-form-item>
            </el-col>            </el-row>
        </el-form>
        <span slot="footer" class="dialog-footer" >
            <el-button @click="showPopup=false"     > Renunta  </el-button>
            <el-button type="primary" @click="save" > Salveaza </el-button>
        </span>
    </el-dialog>
</template>

<script>
    import settings from "@/backend/LocalSettings";
    import BasePage from '@/pages/BasePage';

    export default {
        name: "Clubs_dialog",
        extends: BasePage,
        data () {
            return {
                baseUrl :'',
                showPopup: false,
                mode: 'add',
                selectedObject: {
                    Name: '' , Address: '' , About: '' , EventsUrl: '' , TicketsUrl: '' , HasClubHost: '' , 
                    PausedTableReservation: '0', PausedGuestList: '0',
                    HasTickets: '' , HasTables: '' , HasGuestList: '' , HasLimoPickup: '' , TableLayoutUrl: '' , TableInfo: '' , 
                    ClubType: '' , ImageFile: '' , uploadPreview_ImageFile : '' 
                },
                Info:{},
                rules: {
                    //   Nume: [ { required: true, message: "Campul este obligatoriu" } ]
                    Name: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    Address: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    About: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    EventsUrl: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    TicketsUrl: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    HasClubHost: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    HasTickets: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    HasTables: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    HasGuestList: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    HasLimoPickup: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    TableLayoutUrl: [ { required: false, message: 'Campul este obligatoriu' } ], 
                    TableInfo: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    ClubType: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    ImageFile: [ { required: true, message: 'Campul este obligatoriu' } ], 
                }
            }
        },
        methods: {
            show_me: async function( id ) {
                this.showPopup        = true;
                if( id == null )
                {
                    this.mode = "add";
                }
                else
                {
                    this.mode             = "edit";
                    var            result = await this.post("clubs/get_info_item_dialog", { id: id } );
                    this.selectedObject   = result.Item;
                }
            },
            async get_info(){
                var response = await this.post("clubs/get_info_for_dialog" );
                            },
             handleUploadSuccess_ImageFile(response){
                    this.selectedObject.ImageFile = response.FileName;
                    this.selectedObject.uploadPreview_ImageFile  = response.Url;
                },
                    save: async function() {
                this.$refs['my-form'].validate( async(valid) => {
                    if (valid)
                    {
                        await this.post("clubs/save", { mode: this.mode, object: this.selectedObject } );
                        this.showPopup = false;
                        this.$emit("save");
                    }
                });
            }
        },
        mounted: function() {
            this.baseUrl = settings.BASE_URL;
            this.get_info();
        }
    };
</script>

<style lang="less" scoped>
    .full-width {
        width: 100%;
    }

</style>